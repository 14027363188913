import React, { useState } from 'react';
import { Popover, Typography } from 'antd';

import UserMenu from './UserMenu';
import AvatarWithUsernameGQL from './AvatarWithUsername/graphql';

import * as Styled from './styledComponents';

import { isLocalBuild, isStaging, isDevelopment } from '@project/utils';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { pathToBreadcrumbs } from '@project/utils/URLParsers';

const Header = () => {
    const { pathname } = useLocation();
    const title = pathToBreadcrumbs(pathname);
    const [menuVisible, setMenuVisible] = useState(false);

    const openUserMenu = () => setMenuVisible(true);

    const closeUserMenu = () => setMenuVisible(false);

    return (
        <Styled.Container>
            <h1>{title?.pop()?.label}</h1>
            <Popover
                style={{ width: 400 }}
                open={menuVisible}
                trigger="contextMenu"
                onOpenChange={closeUserMenu}
                content={<UserMenu beforeNavigate={closeUserMenu} />}
                placement="bottomRight"
            >
                <AvatarWithUsernameGQL openMenu={openUserMenu} />
            </Popover>
        </Styled.Container>
    );
};

export default Header;
